@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables.css';

@layer base {
  ::-moz-selection {
    color: theme('colors.white');
    background: theme('colors.lightBlue.500');
  }
  ::selection {
    color: theme('colors.white');
    background: theme('colors.lightBlue.500');
  }

  body {
    @apply m-0 p-0 font-sans font-normal leading-relaxed text-text-primary;
  }

  /* Can't do this (Access the HTML element) with TW selectors */
  html[data-whatintent='keyboard'] .accordion-button:focus {
    @apply text-lightBlue-700;
  }

  html[data-whatinput='keyboard'] .show-password-button:focus,
  html[data-whatinput='keyboard'] .peer\/radio-button-input:focus + .radio-button,
  html[data-whatinput='keyboard'] .peer:focus + .radio-tab-button {
    @apply ring-2 ring-lightBlue-700;
  }

  html[data-whatinput='keyboard'] .show-password-button:focus span:nth-child(2) svg path {
    @apply fill-lightBlue-700;
  }

  html[data-whatintent='keyboard'] .toggle-input:focus + label {
    @apply rounded ring-2 ring-lightBlue-700;
  }

  html[data-whatintent='keyboard'] .cargo-option-button:focus {
    @apply border-lightBlue-700 !outline-lightBlue-500;
  }

  html[data-whatintent='mouse'] .cargo-option-button.active {
    @apply border-lightBlue-500 outline !outline-lightBlue-500;
  }
}

@layer utilities {
  .align-items-unset {
    align-items: unset;
  }

  .justify-left {
    @apply justify-start;
  }

  .justify-right {
    @apply justify-end;
  }

  .rich-text-h1 {
    @apply mb-4 font-semibold leading-tight tracking-tight first:mt-0 last:mb-0 rich-text-h1:text-md lg:mb-6 lg:mt-6 lg:text-lg xl:text-xl text-blue-800;
  }

  .rich-text-h2 {
    @apply font-medium leading-snug first:mt-0 last:mb-0 rich-text-h2:text-md lg:mb-6 lg:mt-6 lg:text-lg text-blue-800;
  }

  .rich-text-h3 {
    @apply font-medium leading-snug first:mt-0 last:mb-0 rich-text-h3:text-md lg:mb-6 lg:mt-6 text-blue-800;
  }

  .rich-text-h4 {
    @apply font-medium leading-snug first:mt-0 last:mb-0 rich-text-h4:text-baseLg lg:mb-6 lg:mt-6 text-blue-800;
  }

  .rich-text-h5 {
    @apply font-medium leading-snug first:mt-0 last:mb-0 rich-text-h5:text-baseSm lg:mb-6 lg:mt-6 text-blue-800;
  }

  .rich-text-h6 {
    @apply font-semibold uppercase leading-snug first:mt-0 last:mb-0 rich-text-h6:text-sm lg:mb-6 lg:mt-6 text-blue-800;
  }

  .rich-text-p {
    @apply leading-relaxed first:mt-0 last:mb-0 rich-text-p:text-baseSm lg:mb-6 lg:mt-6 lg:text-base;
  }

  .rich-text-mailto a[href^='mailto:'] {
    @apply border-b border-lightBlue-600 hover:border-transparent hover:bg-lightBlue-600 hover:text-white;
  }

  .rich-text-a {
    @apply font-normal text-lightBlue-600 hover:no-underline;
  }

  .rich-text a[href^='mailto'] {
    @apply break-words break-all;
  }

  .rich-text-img {
    @apply rounded-lg;
  }

  .rich-text-blockquote {
    @apply m-6 px-lg py-4 font-normal !not-italic [&>footer]:block [&>footer]:!italic;
  }

  .rich-text-ul > li > p:only-of-type {
    @apply m-0;
  }

  .rich-text-ol > li > p:only-of-type {
    @apply m-0;
  }

  .body-overlay {
    @apply pointer-events-none fixed bottom-0 left-0 right-0 top-16 z-2 block w-screen bg-black/30 bg-opacity-0 opacity-0 transition-opacity duration-500 ease-in-out md:hidden;
  }

  .scrollbar {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: theme('colors.grey.200') theme('colors.grey.400');
  }

  .scrollbar::-webkit-scrollbar {
    width: theme('spacing.2');
  }

  .scrollbar::-webkit-scrollbar-track {
    background: theme('colors.grey.200');
    border-radius: theme('borderRadius.lg');
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: theme('colors.grey.400');
    border-radius: theme('borderRadius.lg');
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  /* Ad control */
  ins.adsbygoogle {
    background: rgba(0, 0, 0, 0.025);
  }

  ins.adsbygoogle[data-ad-status='unfilled'] {
    display: none !important;
  }

  .blockquote::before {
    content: open-quote;
  }

  .blockquote::after {
    content: close-quote;
  }

  .blockquote {
    quotes: '“' '”' '‘' '’';
  }

  /* Shallow screen */
  @media (max-height: 1024px) {
    .port-details-wrapper {
      @apply overflow-y-auto overflow-x-hidden;
    }
  }

  @media (min-height: 1024px) and (max-height: 1024px) {
    .port-details-wrapper {
      @apply -mr-4;
    }
  }

  .marquee-mask {
    mask-image: linear-gradient(to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 20%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 0));
  }
}
