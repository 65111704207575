:root {
  --app-header-height: 60px;
  --app-search-bar-height: 224px;
  --app-search-bar-height-md: 145px;
  --app-search-bar-height-lg: 89px;
  --app-mobile-header-height: 64px;
  --filters-side-panel-width: 220px;
  --filters-toggle-bar-width: 40px;
  --center-content-max-width: 1270px;
  --narrow-center-content-max-width: 640px;
  --app-breadcrumbs-height: 41px;
  --app-breadcrumbs-height-md: 49px;
  --page-header-height: 100px;
  --input-height: 42px;
  --input-height-small: 32px;
  --container-max-width: 1240px;
  --container-max-width-slim: 1100px;
  --timeline-grid-cols: 600px 1fr 180px;
  --timeline-spacing: 38px 140px 140px 140px 140px 1fr 120px;
  --timeline-spacing-with-price: 38px 140px 140px 140px 140px 1fr 120px 60px;
  --transition-ease-out-back: cubic-bezier(0.17, 0.89, 0.33, 1.29);
  --route-card-grid-cols-md: 1fr 130px 120px 54px;
  --route-card-grid-cols-xl: 320px 130px 114px 120px 1fr 54px;
  --striped-light: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAAXNSR0IArs4c6QAAADtJREFUKFNjZICC/////4exQTQjIyMjmAYRuCTBCvBJYiiAGYtsLdwEbJJwE3BJwh2J7HoMN+GVBHoVAJHmK/mFm0SxAAAAAElFTkSuQmCC');
}
